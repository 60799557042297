<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div id="pdfDom">
      <div>
        <el-row>
          <el-col :sm="6" :xs="24">
            <el-page-header @back="goBack" :content="$t(title)">
            </el-page-header>
          </el-col>
          <el-col :sm="18" :xs="24" class="col-flex">
            <el-button type="danger" @click="toGetPdf">{{
              $t("common.exportpdf")
            }}</el-button>
            <template
              v-if="orderData.status > 0 && operationByState[orderData.status]"
            >
              <template
                v-for="(optKey, optIndex) in operationByState[orderData.status]"
              >
                <order-operation
                  :key="`opt-${optIndex}`"
                  :name="`content.order.orderOperationText.${optKey}`"
                  :icon="optMap[optKey].icon"
                  :fun="optMap[optKey].fun"
                  :ids="[id]"
                  :type="optMap[optKey].type"
                  :callback="getDetail"
                />
              </template>
            </template>
          </el-col>
        </el-row>
      </div>

      <info-list
        title="content.order.storeInfo"
        v-model="store"
        :names="names"
      />
      <info-list title="content.order.userInfo" v-model="user" :names="names" />
      <info-list
        title="content.order.orderInfo"
        v-model="orderData"
        :names="names"
        :exhibit="orderExhibit"
        :to="to"
      />
      <template v-if="orderAttachList && orderAttachList.length">
        <h4 class="detail-h4">{{ $t("content.order.Attach list") }}</h4>
        <sui-list
          ref="orderAttachList"
          :column="orderAttachTableColumn"
          :defaultData="orderAttachList"
        />
      </template>
      <h4 class="detail-h4">{{ $t("content.order.Sub order list") }}</h4>
      <sui-list
        v-if="orderList.length"
        ref="orderList"
        :column="orderTableColumn"
        :defaultData="orderList"
        :refactor="tableDataRefactor"
      />
      <h4 class="detail-h4">{{ $t("content.order.paymentdetails") }}</h4>
      <sui-list
        v-if="orderPayList.length"
        ref="orderPayList"
        :column="orderPayListColumn"
        :defaultData="orderPayList"
      />
    </div>
  </Layout>
</template>

<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import SuiList from "@/components/s-ui/list";
import InfoList from "@/components/InfoList";
import OrderOperation from "@/components/OrderOperation";
import {
  GetOrderDetail,
  ConfirmPrepayment,
  ConfirmNoPrepayment,
  CanceOrder,
  ConfirmBalance,
  ConfirmNoBalance,
  GetOrderPayList,
} from "@/utils/api";
import { formatDataTime } from "@/utils";
export default {
  components: {
    Layout,
    PageHeader,
    SuiList,
    OrderOperation,
    InfoList,
  },
  data() {
    return {
      page: "",
      id: 0,
      title: "menuitems.order.detail",
      items: [
        {
          text: this.$t("menuitems.order.management"),
        },
        {
          text: this.$t("menuitems.order.detail"),
          active: true,
        },
      ],
      orderList: [],
      orderAttachList: [],
      orderPayList: [],
      orderTableColumn: [
        {
          name: "orderNo",
          label: "content.order.orderno",
        },
        {
          name: "productName",
          label: "content.order.productName",
        },
        {
          type: "image",
          name: "productImg",
          label: "content.order.productImg",
        },
        {
          name: "spData",
          label: "content.order.spData",
        },
        {
          name: "pcs",
          label: "content.order.pcs",
        },
        // {
        //   name: "price",
        //   label: "content.order.price",
        //   formatFn: formatToDecimal,
        //   width: 100,
        // },
        {
          type: "price-fixed",
          name: "price",
          label: "content.order.price",
          width: 100,
        },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 100,
        },
        // {
        //   name: "total",
        //   label: "content.order.total",
        //   formatFn: formatToDecimal,
        //   width: 100,
        // },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      orderAttachTableColumn: [
        {
          name: "type",
          label: "content.order.type",
        },
        {
          name: "payMethod",
          label: "content.order.payMethod",
        },
        {
          name: "remark",
          label: "content.order.remark",
        },
        {
          type: "image",
          name: "imgList",
          label: "content.order.imgList",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      orderPayListColumn: [
        {
          name: "outTradeNo",
          label: "content.order.orderno",
        },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 150,
        },

        {
          name: "payMethod",
          label: "content.order.payMethod",
          payMethod: this.$t("content.order.payMethodText"),
        },
        {
          name: "codeUrl",
          label: "content.order.payCodeUrl",
        },
        {
          name: "status",
          label: "content.order.status",
          payStatus: this.$t("content.order.payStatusText"),
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      // fun: {
      //   301: ConfirmPrepayment,
      //   302: ConfirmNoPrepayment,
      // },
      operationByState: {
        // 不同状态下，显示不同的操作按钮 当前状态: [操作后状态, 操作后状态]
        101: [1301],
        201: [301, 302],
        501: [601, 602],
      },
      optMap: {
        // 操作按钮枚举
        1301: {
          icon: "el-icon-circle-close",
          type: "danger",
          fun: CanceOrder,
        },
        301: {
          icon: "el-icon-circle-check",
          fun: ConfirmPrepayment,
        },
        302: {
          icon: "el-icon-circle-close",
          fun: ConfirmNoPrepayment,
          type: "danger",
        },
        601: {
          icon: "el-icon-circle-check",
          fun: ConfirmBalance,
        },
        602: {
          icon: "el-icon-circle-close",
          fun: ConfirmNoBalance,
          type: "danger",
        },
      },
      tableDataRefactor: (list) => {
        // console.log("list", list);
        return list.map((v) => {
          return {
            ...v,
            currencyTo: v.orderList[0].currencyTo,
          };
        });
      },
      orderData: {},
      orderExhibit: {
        status: { type: "state" },
        total: { type: "price" },
      },
      user: {},
      store: {},
    };
  },
  computed: {
    names: function () {
      return this.$t("content.order");
    },
    to: function () {
      return this.orderList[0] ? this.orderList[0].currencyTo : "";
    },
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.getDetail();
    this.getOrderPayList();
  },
  methods: {
    getOrderPayList() {
      GetOrderPayList({ id: this.id })
        .then((res) => {
          // console.log("GetOrderPayList-res", res);
          const orderPayList = res.data.rows;
          // console.log(orderPayList, "orderPayList");
          this.orderPayList = orderPayList.map((v) => {
            return {
              ...v,
              outTradeNo: v.Pay.outTradeNo,
              currencyTo: v.Pay.currency,
              total: v.Pay.total,
              createAt: v.Pay.createAt,
              status: v.Pay.status,
              codeUrl: v.Pay.codeUrl,
              payMethod: v.Pay.payMethod,
            };
          });
        })
        .catch((err) => {
          console.log("GetOrderPayList-err", err);
        });
    },
    // 导出为pdf
    toGetPdf() {
      const h = this.$createElement;
      this.$msgbox({
        title: this.$t("common.exportpdf"),
        message: h("div", null, [
          h("table", { style: "width: 100%; border-collapse: collapse;" }, [
            h("tbody", null, [
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.orderno")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.orderNo
                ),
              ]),
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.consignee")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.consignee
                ),
              ]),
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.pcs")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.pcs
                ),
              ]),
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.cbm")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.cbm
                ),
              ]),
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.logisticsType")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.logisticsType
                ),
              ]),
              h("tr", null, [
                h(
                  "td",
                  {
                    style: "border: 1px solid #ccc; padding: 8px;width:150px;",
                  },
                  this.$t("content.order.address")
                ),
                h(
                  "td",
                  { style: "border: 1px solid #ccc; padding: 8px;" },
                  this.orderData.address
                ),
              ]),
              // 添加更多行
            ]),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t("common.ok"),
        cancelButtonText: this.$t("common.cancel"),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";

            // 调用导出 PDF 的函数
            this.getPdf();

            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 1500);
          } else {
            done();
          }
        },
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },

    getPdf() {
        const doc = new jsPDF({
        orientation: "landscape", // 设置方向为横向
        unit: "mm", // 设置单位为毫米
        format: "a4", // 设置页面格式为 A4
        putOnlyUsedFonts: true,
        floatPrecision: 5, // 设置浮点数精度
      });

      // 设置页面大小和缩放比例
      const scaleFactor = 1.5; // 设置缩放比例为 80%
      const pageWidth = doc.internal.pageSize.getWidth() * scaleFactor;
      const pageHeight = doc.internal.pageSize.getHeight() * scaleFactor;
      console.log("pageWidth", pageWidth, pageHeight);
      

      // 添加标题
    //   doc.setFontSize(18);
    //   doc.text(this.$t("SOKOGATE"), 14, 20);

      // 添加表格
      const tableData = [
        [this.$t("content.order.orderno"), this.orderData.orderNo],
        [this.$t("content.order.consignee"), this.orderData.consignee],
        [this.$t("content.order.pcs"), this.orderData.pcs],
        [this.$t("content.order.cbm"), this.orderData.cbm],
        [this.$t("content.order.logisticsType"), this.orderData.logisticsType],
        [this.$t("content.order.address"), this.orderData.address],
        // 添加更多行
      ];

      doc.autoTable({
        head: [["SOKOGATE", ""]],
        body: tableData,
        startY: 10, // 表格开始的 Y 坐标
        theme: "grid", // 表格主题
        styles: { cellPadding: 2, fontSize: 40, fontStyle: "bold" }, // 表格样式
      });

      // 保存 PDF
      doc.save("export.pdf");
    },

    getDetail() {
      GetOrderDetail({ id: this.id })
        .then((res) => {
          const { orderList, orderAttachList, ...orderData } =
            res.data.OrderDetail;
          this.orderList = orderList.map((v) => {
            return {
              ...v,
              productImg: v.productImg || v.productGalleryImg,
              currencyTo: v.currencyTo,
            };
          });
          this.orderAttachList = orderAttachList;
          this.$refs["orderAttachList"] &&
            this.$refs["orderAttachList"].setList(orderAttachList);
          const {
            // 订单
            orderNo,
            cbm,
            total,
            status,
            createAt,
            updateAt,
            // 店铺
            storeId,
            storeName,
            // 用户
            userId,
            username,
            avatar,
            // 收货地址
            // consignee,
            phone,
            pcs,
            country,
            province,
            city,
            district,
            detail,
            email,
            logisticsType,
            user,
            weight,
            // 其它
            ...others
          } = orderData;

          this.orderData = {
            orderNo,
            weight,
            pcs,
            total,
            cbm : cbm + " / " + weight +'KG',
            status,
            createAt: formatDataTime(createAt),
            updateAt: formatDataTime(updateAt),
            consignee: `${username} (${user.phoneCode}-${phone} ${email})`,
            address: `${country} ${province} ${city} ${district} ${detail}`,
            logisticsType:
              logisticsType == 301
                ? this.$t("content.order.airTransport")
                : this.$t("content.order.oceanShipping"),
          };
          this.store = { storeId, storeName };
          this.user = { userId, username, avatar };
          console.log("others:", others);
          console.log("0000000000000", this.orderData);
        })
        .catch((err) => {
          console.log("GetOrderDetail-err:", err);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="scss">
.col-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.detail-h4 {
  color: #333;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: normal;
}
</style>